export const NOTIFICATION_CHANNELS = {
  WEB: 'web',
  EMAIL: 'email',
};

export const EMAIL_CADENCE = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  IMMEDIATELY: 'Immediately',
  NEVER: 'Never',
};
